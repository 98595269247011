.react-daterange-picker {
  width: 100%;
}

.react-daterange-picker__wrapper {
  width: 100%;
}

.react-daterange-picker__calendar {
  display: none;
}

.react-daterange-picker__inputGroup {
  flex-grow: 0;
}

/* Permette di non troncare la select "Assegnato a",
 * ma genera la visualizzazione sovrapposta sull'animazione dell'accordion
 */
.rc-collapse-content {
  overflow: initial;
  padding-top: 16px;
  padding-bottom: 16px;
  /* clear: both;
  height: 100%;
  display: inline-block; */
}

.rc-collapse-content > .rc-collapse-content-box {
  margin: 0px;
}
