.rbt {
  outline: none;
}
.rbt-menu {
  margin-bottom: 2px;
}
.rbt-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-menu > li a:focus {
  outline: none;
}
.rbt-menu-pagination-option {
  text-align: center;
}
.rbt .rbt-input-main::-ms-clear {
  display: none;
}
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  height: auto;
}
.rbt-input-multi.focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
  outline: 0;
}
.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}
.rbt-input-multi input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.rbt-input-multi input:-ms-input-placeholder {
  color: #999;
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #999;
}
.rbt-input-multi .rbt-input-wrapper {
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
  height: 20px;
  margin: 1px 0 4px;
}
.rbt-input-multi .rbt-input-hint-container {
  display: inline-block;
}
.rbt-input-multi.input-lg .rbt-input-main, .rbt-input-multi.form-control-lg .rbt-input-main {
  height: 24px;
}
.rbt-input-multi.input-sm .rbt-input-main, .rbt-input-multi.form-control-sm .rbt-input-main {
  height: 18px;
}
.rbt-close {
  z-index: 1;
}
.rbt-close-lg {
  font-size: 24px;
}
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
  border-radius: 5px;
}
.rbt-token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}
.rbt-token-removeable {
  cursor: pointer;
  /*z-index: 999;*/
  padding-right: 21px;
  padding-left: 21px;
}
.rbt-token-active {
  /*background-color: #1f8dd6 !important;*/
  background-color: #c8ced3 !important;
  color: #fff !important;
  border: 1px solid #23282c;
  outline: none;
  text-decoration: none;
}
.rbt-token-click {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  /* background-color: #1f8dd6 !important; */
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit !important;;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}
.rbt-token-color-1 {
  background-color: #0062cc;
  color: #fff;
}
.rbt-token-color-2 {
  background-color: #dc3545;
  color: #fff;
}
.rbt-token-color-3 {
  background-color: #ffc107;
  color: #fff;
}
.rbt-token-color-4 {
  background-color: #545b62;
  color: #fff;
}
.rbt-token-color-5 {
  background-color: #17a2b8;
  color: #fff;
}
.rbt-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
  -o-animation: loader-animation 600ms infinite linear;
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #1f8dd6;
  display: block;
  height: 16px;
  width: 16px;
}
.rbt-loader-lg {
  height: 20px;
  width: 20px;
}
.rbt-aux {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-aux-lg {
  width: 46px;
}
.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}
.has-aux .rbt-input {
  padding-right: 34px;
}
.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}
/* Input Groups */
.input-group > .rbt {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.input-group > .rbt .rbt-input-hint-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.input-group > .rbt .rbt-input-hint {
  z-index: 5;
}
.input-group > .rbt .rbt-aux {
  z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

/* Inibisce la visualizzazione delle icone di bootstrap
   relative alla validazione della formula */
.form-control.is-valid,
.form-control.is-invalid {
  background: initial;
}
