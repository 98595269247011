.NE-background {
  background-color: white;
}

.NE-body-severity {
  background-color: white;
  color: #212529;
  font-size: 0.9rem;
}
.NE-progress-severity-medium {
  background: #f5a623;
}
.NE-progress-severity-critical {
  background: #d0021b;
}
.NE-progress-severity-low {
  background: #f8e71c;
}
.NE-progress-severity-info {
  background: #9bddf2;
}
.NE-progress-severity-end {
  background: #00965a;
}