.Page {
    text-align: center;
}

.Page-logo {
    height: 40vmin;
    pointer-events: none;
}

.Page-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

h2 {
    color: #1F71B7;
}