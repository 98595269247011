.leaflet-marker-icon.marker-cluster.marker-cluster-small.leaflet-zoom-animated.leaflet-interactive,
.leaflet-marker-icon.marker-cluster.marker-cluster-medium.leaflet-zoom-animated.leaflet-interactive,
.leaflet-marker-icon.marker-cluster.marker-cluster-big.leaflet-zoom-animated.leaflet-interactive {
  background-color: #75a5cf;
}

.leaflet-marker-icon.marker-cluster.marker-cluster-small.leaflet-zoom-animated.leaflet-interactive div,
.leaflet-marker-icon.marker-cluster.marker-cluster-medium.leaflet-zoom-animated.leaflet-interactive div,
.leaflet-marker-icon.marker-cluster.marker-cluster-big.leaflet-zoom-animated.leaflet-interactive div {
  background-color: #1f71b7;
}

.marker-cluster span {
  color: #fff;
}
